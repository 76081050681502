import { Color, Header, PageLoader } from '@vodafoneziggo/sandwich';
import { AuthStatus, AuthWrapperContext } from 'components/Authentication/Wrapper/Wrapper';
import { BaseLayoutContext } from 'components/BaseLayout/BaseLayout';
import { Footer } from 'components/Footer';
import HeaderSpace from 'components/HeaderSpace';
import MaintenanceMessages from 'components/MaintenanceMessages';
import { IMaintenanceMessage } from 'components/MaintenanceMessages/maintenance-message.model';
import useMaintenanceMessages from 'components/MaintenanceMessages/use-maintenance-messages';
import Nba from 'components/Nba';
import ThemeContext from 'components/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'styles/elements/grid.styles';
import { ViewContext, getViewContextFromUserAgent } from 'utils/webview-detection';
import * as S from './page.styles';

interface Props {
  title?: string;
  metaTitle?: string;
  className?: string;
  hideHeader?: boolean;
  selector?: React.ReactNode;
  showNba?: boolean;
  headerConversation?: React.ReactNode;
  children: React.ReactNode;
  forceVisible?: boolean;
}

interface ISupportPageProps {
  title: string;
  metaTitle: string;
  children: React.ReactNode;
  testId?: string;
}

interface PropsWithChildren {
  children: React.ReactNode;
}

interface SidebarProps extends PropsWithChildren {
  lg?: number;
}

interface ContentProps extends PropsWithChildren {
  lg?: number;
}

export const Wrapper = ({ children }: PropsWithChildren) => <S.Wrapper>{children}</S.Wrapper>;

export const ContentBlocksWrapper = ({ children }: PropsWithChildren) => (
  <S.ContentBlocksWrapper>{children}</S.ContentBlocksWrapper>
);

export const TilesWrapper = ({ children }: PropsWithChildren) => <S.TilesWrapper>{children}</S.TilesWrapper>;

export const Content = ({ children, lg = 8 }: ContentProps) => (
  <Col lg={lg}>
    <S.Column>{children}</S.Column>
  </Col>
);

export const Sidebar = ({ children, lg = 4 }: SidebarProps) => <S.Sidebar lg={lg}>{children}</S.Sidebar>;

export const FullWidthContent = ({ children }: PropsWithChildren) => (
  <Col lg={12}>
    <S.Column>{children}</S.Column>
  </Col>
);

export const CenteredContent = ({ children }: PropsWithChildren) => (
  <Col lg={6} offset={3}>
    <S.Column>{children}</S.Column>
  </Col>
);

interface PageContentProps extends Props {
  activeMessage: IMaintenanceMessage | undefined;
  isMaintenanceMessagesLoading: boolean;
}

const PageContent = ({
  title,
  metaTitle,
  className,
  hideHeader,
  selector,
  headerConversation,
  showNba,
  children,
  forceVisible,
  activeMessage,
  isMaintenanceMessagesLoading,
  ...restProps
}: PageContentProps) => {
  const context = useContext(ThemeContext);
  const baseLayoutContext = useContext(BaseLayoutContext);
  const status = useContext(AuthWrapperContext);
  const pageLoaded = status === AuthStatus.READY || forceVisible;

  const [loading, setLoading] = useState(true);
  const [showHeaderSpace, setShowHeaderSpace] = useState(false);
  const [shouldHideFeedbackButton, setShouldHideFeedbackButton] = useState(false);

  useEffect(() => {
    document.title = `${metaTitle ? `${metaTitle} - ` : ''}Vodafone.nl`;
  }, [metaTitle]);

  useEffect(() => {
    const checkViewContext = () => {
      const Context = getViewContextFromUserAgent();

      if (Context === ViewContext.APP) {
        baseLayoutContext.setShowHeaderAndFooter(false);
        setShowHeaderSpace(false);
        setShouldHideFeedbackButton(true);
      } else {
        baseLayoutContext.setShowHeaderAndFooter(true);
        setShowHeaderSpace(true);
        setShouldHideFeedbackButton(false);
      }

      setLoading(false);
    };

    checkViewContext();
  }, [baseLayoutContext]);

  useEffect(() => {
    const feedbackButton = document.querySelector('[data-testid="feedback-button"]') as HTMLElement;

    if (feedbackButton) {
      feedbackButton.style.display = shouldHideFeedbackButton ? 'none' : 'block';
    }
  }, [shouldHideFeedbackButton]);

  if (isMaintenanceMessagesLoading || loading) {
    return <PageLoader minHeight="300px" />;
  }

  if (activeMessage) {
    return <MaintenanceMessages message={activeMessage} />;
  }

  return (
    <div {...restProps} className={context}>
      {!hideHeader && showHeaderSpace && (
        <HeaderSpace title={title} selector={selector} conversation={headerConversation} />
      )}
      {showNba && (
        <S.Nba>
          <Nba />
        </S.Nba>
      )}
      {pageLoaded ? <S.Page className={className}>{children}</S.Page> : <PageLoader minHeight="300px" />}
    </div>
  );
};

const Page = (props: Props) => {
  const { activeMessage, isLoading } = useMaintenanceMessages();

  return <PageContent {...props} activeMessage={activeMessage} isMaintenanceMessagesLoading={isLoading} />;
};

export const SupportPage = ({ title, metaTitle, children, testId }: ISupportPageProps) => {
  const baseLayoutContext = useContext(BaseLayoutContext);
  const { activeMessage, isLoading } = useMaintenanceMessages();
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(false);

  useEffect(() => {
    const updateHeaderAndFooterVisibility = () => {
      const Context = getViewContextFromUserAgent();

      baseLayoutContext.setShowHeaderAndFooter(false);

      if (Context === ViewContext.APP) {
        setShowHeaderAndFooter(false);
      } else {
        setShowHeaderAndFooter(true);
      }
    };

    updateHeaderAndFooterVisibility();
  }, [baseLayoutContext]);

  useEffect(() => {
    document.title = `${metaTitle} - Vodafone.nl`;
  }, [metaTitle]);

  if (isLoading) {
    return <PageLoader minHeight="300px" />;
  }

  if (activeMessage) {
    return <MaintenanceMessages message={activeMessage} />;
  }

  return (
    <>
      {showHeaderAndFooter && (
        <Header
          changeBillingCustomer={() => null}
          billingCustomers={[]}
          activeBillingCustomer={null}
          shouldShowMenuItem={() => Promise.resolve(false)}
          shouldRenderSubmenu={false}
        />
      )}
      <HeaderSpace title={title} backgroundColor={Color.BLACK} />
      <S.Page data-testid={testId}>{children}</S.Page>
      {showHeaderAndFooter && <Footer />}
    </>
  );
};

export default Page;
