import { useAuthSubscription } from 'containers/auth';
import { ContainerStatuses, defaultLoadingStatus } from 'models/container-statuses.model';
import { ISubscription } from 'models/subscription.model';
import { useEffect, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import api from 'utils/api';
import { makeRequestReducer } from 'utils/api/useApiRequest';
import DateFNS from 'utils/date';
import { wrapWithCancellation } from 'utils/function';
import { triggerApiErrorEvent } from 'utils/tracking';
import { DevicePayOffFormValues } from './DeviceAndSim/pages/DevicePayOff/device-pay-off.model';
import { IPauseSubscription } from './Subscription/pages/PauseSubscription/pause-subscription.model';
import { IContract, IDeviceRc, IProduct } from './models';

export enum ProductServiceType {
  LOAN_DEVICE = 'LD',
  MOBILE_OFFER = 'MO',
}
interface ProductsState {
  product: IProduct;
  loans: IDeviceRc[];
  contract: IContract;
}

function useProducts() {
  const reducer = makeRequestReducer<ProductsState>();
  const [state, dispatch] = useReducer(reducer, defaultLoadingStatus);

  /**
   * Method that can be used to (re)fetch the products. The useEffect watches the
   * fetchTick value and will redo its work when the value changes.
   */
  const [fetchTick, fetchProducts] = useReducer(x => x + 1, 0);

  /**
   * Extract required auth values and reset the request hook whenever auth values change
   * so the request returns to its initial loading state.
   */
  const { bcId, productId } = useAuthSubscription();

  useEffect(() => {
    if (!bcId || !productId) return;

    dispatch({ type: ContainerStatuses.LOADING });

    const cancellation = wrapWithCancellation();

    const getProduct = api.get(`/my/product/${bcId}/${productId}?with_addons=false`);

    getProduct
      .then(
        cancellation.wrapper(productResponse => {
          let loans = [];

          if (Array.isArray(productResponse.data.device_rc)) {
            loans = productResponse.data.device_rc.filter((loan: IDeviceRc | undefined) => loan !== undefined);
          } else if (productResponse.data.device_rc !== undefined) {
            loans = [productResponse.data.device_rc];
          }

          const newState = {
            product: productResponse.data,
            loans,
            contract: productResponse.data.contract,
          };

          dispatch({
            type: ContainerStatuses.READY,
            data: newState,
          });
        })
      )
      .catch(
        cancellation.wrapper(errors => {
          dispatch({ type: ContainerStatuses.FAILED, errors: errors.errorMessages });
          triggerApiErrorEvent('products.container', errors.status, errors.errorMessages);
        })
      );

    return cancellation.cancel;
  }, [bcId, productId, fetchTick]);

  return {
    state,

    fetchProducts,

    /* eslint-disable-next-line require-await */
    postRequestNewMobileNumber: async (subscription: ISubscription) => api.post('/my/changemsisdn', subscription),

    /* eslint-disable-next-line require-await */
    submitTimeoutForm: async (values: IPauseSubscription) =>
      api.post('/my/timeout', {
        ...values,
        start_date: DateFNS.format(values.start_date, 'dd-MM-yyyy').toString(),
        end_date: values.end_date && DateFNS.format(values.end_date, 'dd-MM-yyyy').toString(),
      }),

    /* eslint-disable-next-line require-await */
    postRequestDevicePayOff: async (formValues: DevicePayOffFormValues) => {
      const response = await api.post(`/my/device/loan/payoff`, {
        billing_customer_id: formValues?.billing_customer_id,
        loan_id: formValues?.loan_id,
        loan_amount: String(formValues?.loan_amount),
        loan_start_date: String(formValues?.loan_start_date),
        loan_end_date: String(formValues?.loan_end_date),
        phone_number: formValues?.phone_number,
        address: formValues?.address,
        email: formValues?.email,
        initial: formValues?.initial,
        last_name: formValues?.last_name,
        emailconfirm: formValues?.email_repeat,
        notification_type: formValues?.notification_by === 'email' ? 'Email' : null,
      });

      return response;
    },

    getLoanById: (loanId: string) => {
      // Use optional chaining to avoid errors if state.data or state.data.loans is null or undefined
      return state.data?.loans.find(loan => loan.id === loanId);
    },
  };
}

export default createContainer(useProducts);
